.Header {
    width: var(--page-width);
    position: absolute;
}


.Header-backdrop {
    width: 0px;
    height: 0px;
    border-left: calc(var(--page-width)*9/40) solid transparent;
    border-bottom: calc(1.1*var(--page-width)) solid var(--popout-color);
    -webkit-transform:
        translateX(calc(var(--page-width)/2.5))
        translateY(calc(-1*var(--page-width)/(20.5/9)))
        rotate(-90deg);
    position: absolute;
    background-clip: border-box;
    z-index: 10;
}
