.HistoryEntry {
    width: calc(2/3*var(--page-width) - 25px);
    background-color: rgb(229, 228, 228);
    border-radius: 10px;
    padding-left: 5px;
    margin-top: 10px;
    /*float:right;*/
}

.HistoryEntry-Content {
    z-index: 12;
    padding-right: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
    /*position: relative;*/
}

.HistoryEntry-SubtEntry-Wrapper {
    display:flex;
    flex-wrap: wrap;
    /*justify-content: center;*/
}
.HistoryEntry-SubEntry {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-style: italic;
    min-width: 40%;
}
.HistoryEntry-SubEntry-Title {
    padding-left: 7px;
    /*max-width: calc(((2/3*var(--page-width) - 20px)/2));*/
    max-width: 100%;
    overflow: auto;
}
.HistoryEntry-SubEntry-Title-Full {
    padding-left: 7px;
    /*max-width: calc(((2/3*var(--page-width) - 20px)));*/
    max-width: 100%;
    overflow: auto;
}

.HistoryEntry-ImageWrapper{
    max-height: 300px;
    border-radius: 50%;
    --moz-border-radius: 50%;
    --webkit-border-radius: 50%;
}

.HistoryEntry-ExpandableTitle{
    max-width: calc(((2/3*var(--page-width) - 80px)));
    width: 100%;
}

.HistoryEntry-FixedTitle {
    padding-top: 10px;
    max-width: calc(((2/3*var(--page-width) - 20px)));
    margin-left:2px;
}

.HistoryEntry-ImageWrapper > img{
    --max-val-for-width: calc(var(--history-width) - 30px);
    max-width: calc(min(300px, var(--max-val-for-width)));
    max-height: 80px;
    min-height: 80px;
    margin: auto;
    padding-top: 9px;
    height: auto;
    width: auto;
    display: block;

}


.HistoryEntry-Clickable {
    cursor: pointer;
}

.HistoryEntry-Description {
    font-weight: 400;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 5px;
}