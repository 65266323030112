
.ProgressBar {
    height: 10px;
    position: relative;
    background: black;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    padding: 4px;
    box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
    margin-bottom: 2px;
}

.ProgressBar-Low > span {
    background-image: repeating-linear-gradient(-50deg, rgba(var(--colour-blank-rgb), 0.3), rgba(var(--colour-blank-rgb), 0.3) 20px, var(--colour-blank) 20px, var(--colour-blank) 40px);
    box-shadow:
            inset 0 2px 9px rgba(var(--colour-blank-rgb),0.5),
            inset 0 -2px 6px rgba(var(--colour-blank-rgb),0.4);
}

.ProgressBar-Medium > span {
    background-image: repeating-linear-gradient(-50deg, rgba(var(--colour-progressbar-rgb), 0.3), rgba(var(--colour-progressbar-rgb), 0.3) 20px, var(--colour-progressbar) 20px, var(--colour-progressbar) 40px);
    box-shadow:
            inset 0 2px 9px rgba(var(--colour-progressbar-rgb),0.5),
            inset 0 -2px 6px rgba(var(--colour-progressbar-rgb),0.4);
}

.ProgressBar-High > span {
    background-image: repeating-linear-gradient(-50deg, rgba(var(--colour-confirm-rgb), 0.3), rgba(var(--colour-confirm-rgb), 0.3) 20px,  var(--colour-confirm) 20px, var(--colour-confirm) 40px);
    box-shadow:
            inset 0 2px 9px rgba(var(--colour-confirm-rgb),0.5),
            inset 0 -2px 6px rgba(var(--colour-confirm-rgb),0.4);
}


.ProgressBar > span {
    display: block;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
    overflow: hidden;
    background-size: 100% 100%;
}