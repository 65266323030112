.TaggedImage{
    margin-top: 5px
}

.TaggedImage-ImageWrapper-NoBackground{
    float: left;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    --moz-border-radius: 50%;
    --webkit-border-radius: 50%;
}


.TaggedImage-ImageWrapper{
    float: left;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    --moz-border-radius: 50%;
    --webkit-border-radius: 50%;
    background-color: rgba(var(--colour-round-background-rgb), 0.5);
}

.TaggedImage-Clickable {
    cursor: pointer;
}
.TaggedImage-ImageWrapper-NoBackground > img, .TaggedImage-ImageWrapper > img, .TaggedImage-ImageWrapper > svg, .TaggedImage-ImageWrapper-NoBackground > svg{
    max-width: 30px;
    max-height: 22px;
    margin: auto;
    padding-top: 8px;
    padding-left: 1px;
    height: 100%;
    width: auto !important;
    display: block;
}

.TaggedImage-Body{
    width: calc(100% - 45px);
    margin-left: 45px;
    padding-top: 3px;
}