.LanguageDetails {
    width: calc(2/3*var(--page-width) - 20px);
    padding-bottom: 10px;
}

.LanguageDetails-Content {
    z-index: 12;
    width: 150px;
    text-align: center;
}

.LanguageDetails-ImageWrapper{
    max-width:80px;
    border-radius: 50%;
    --moz-border-radius: 50%;
    --webkit-border-radius: 50%;
    margin-left:auto;
    margin-right:auto;
}


.LanguageDetails-ImageWrapper > img{
    max-width: 80px;
    max-height: 80px;
    margin: auto;
    padding-top: 9px;
    height: auto;
    width: auto;
    display: block;
}
.LanguageDetailsStars {
    margin-left:auto;
    margin-right:auto;
    width: 60%
}