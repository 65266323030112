.Expand {
    padding-top:10px;
    width: 100%;
    overflow: hidden;
}

.Expand-Top {
        width: 100%;
    min-height: 20px;
    border-radius: 5px;
    cursor: pointer !important;
}

.ExpandTop-Coloured {
    background-color: rgba(var(--colour-primary-rgb), 0.4);
    padding-top: 2px;
    padding-bottom: 2px;
}


.Expand-Button {
    width: 20px;
    float: left;
    margin-left: 10px;
    font-family: "Arial Narrow", serif;
}

.Expand-Button > svg {
    height: 20px;
}
.Expand-Title {
    width: calc(100% - 30px);
    margin-left: 10px;
}

.Expand-Bottom {
    width: 100%;
}