.SkillGroup {
    background-color: var(--colour-secondary);
    color: var(--colour-neutral);
    width: calc(1/3*var(--page-width) - 20px);
    float: left;
    min-height: calc(100vh - var(--page-width)/5);
    padding-top: calc(var(--page-width)/8);
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    height: calc(100% - (var(--page-width)/8));
}
.SkillGroup-Content{
    z-index: 12;
    position: relative;
    min-height: 10px;
}
.SkillGroup-ContentBody > .TaggedImage > .TaggedImage-Body > div > a {
    font-size: 15px;
    color: var(--colour-neutral);
    word-break: break-word;
}

.SkillGroup-ContentBody{
    width: 100%;
    float: left;
}
.SkillGroup-Header {
    font-weight: bold;
    text-align: center;
    width: 100%;
    z-index: 12;
}

.SkillGroup-Name {
    /*position: relative;*/
    /*z-index: 11;*/
    /*text-align: right;*/
    /*padding-top: calc(var(--page-width)/10);*/
    /*padding-right: 50px;*/
    font-size: 3em;
    text-align: center;
    width: 100%;
    padding-bottom: 10px;
    /*float: right;*/
    font-family: var(--header-font);
    /*transform: rotate(22deg);*/
    /*filter: drop-shadow(0px 0px 6px var(--colour-secondary));*/
}


.SkillGroupViewContactButton {
    width: 30%;
    margin: auto;
    border-radius: 2px;
}

.SkillGroupViewContactLoading {
    width: 100%;
    margin: auto;
    text-align: center;
    border-radius: 2px;
}

.SkillGroupCaptchaFailure {
    margin: auto;
}

.SkillGroupViewContactButtonWrapper{
    width: 100%;
    display: flex;
    height: 129px;
}