.History {
    background-color: var(--colour-neutral);
    color: black;
    width: var(--history-width);
    float:right;
    padding-top: calc(var(--page-width)/5);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    min-height: calc(100vh - var(--page-width)/3);
}

.History-Content {
    z-index: 12;
    position: relative;
}

.History-Content > h1 {
    text-align: center;
    color: black;
    font-size: 2.5em;
    font-family: var(--header-font);
    /*border-bottom: 1px solid white;*/
}
/*.History-Content > h1:after {*/
/*    content: "";*/
/*    background: white;*/
/*    width: 80%;*/
/*    height: 1px;*/
/*    max-height: 1px;*/
/*    float:left;*/
/*    margin-left: 10%;*/
/*}*/

.History-Language-Wrapper{
    display: flex;
    justify-content: center;
    height:auto;
    width: 100%;
}
.LanguageWrapperDiv {
    float: left;
    width: 150px;
}